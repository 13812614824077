<template>
  <div class="container-fluid">
    <div class="row bg-login-submit">
      <a class="btn login-back btn--dark" href="https://www.mylastwill.co.uk">
        <i class="fa fa-chevron-left"></i>
      </a>
      <div class="sup-container">
        <div class="card"></div>
        <div class="card">
          <h1 class="title">Complete Account</h1>

          <div class="input-container">
            <input
              v-model="form.email"
              type="email"
              required
              class="input-standard-grey validation"
              @keyup.enter="signIn"
              id="email"
              placeholder="Email"
            />
            <label for="email">Email</label>
            <ErrorPop
              v-show="formErrorEmail"
              :message="formErrorEmail"
              @clearError="formErrors.email = null"
            ></ErrorPop>
          </div>

          <div class="input-container">
            <input
              v-model="form.email_repeat"
              type="email"
              required
              class="input-standard-grey validation"
              @keyup.enter="signIn"
              id="email_repeat"
              placeholder="Repeat Email"
            />
            <label for="email_repeat">Repeat Email</label>
          </div>

          <div class="input-container">
            <input
              v-model="form.password1"
              :type="form.passwordType"
              class="input-standard-grey allow-submit"
              @keyup.enter="signIn"
              id="password1"
              placeholder="New Password"
            />
            <label for="password1">New Password</label>
            <span
              class="fa fa-fw fa-eye password-view-icon toggle-password link"
              @click="changeFormPasswordType"
            ></span>
            <span class="fa fa-unlock-alt input-icon"></span>
            <ErrorPop
              v-show="formErrorPassword1"
              :message="formErrorPassword1"
              @clearError="formErrors.password1 = null"
            ></ErrorPop>
          </div>

          <div class="input-container">
            <input
              v-model="form.password2"
              :type="form.passwordType"
              class="input-standard-grey allow-submit"
              @keyup.enter="signIn"
              id="password2"
              placeholder="Password Repeat"
            />
            <label for="password2">Password Repeat</label>
            <span
              class="fa fa-fw fa-eye password-view-icon toggle-password link"
              @click="changeFormPasswordType"
            ></span>
            <span class="fa fa-unlock-alt input-icon"></span>
            <ErrorPop
              v-show="formErrorPassword2"
              :message="formErrorPassword2"
              @clearError="formErrors.password2 = null"
            ></ErrorPop>
          </div>

          <div class="input-container">
            <meter max="4" id="password-strength-meter"></meter>
          </div>

          <div class="inset-container">
            <div class="row terms-holder align-center">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div id="terms-label" class="pb30 css-checkbox light">
                  <input
                    @click="show.agreementModal = true"
                    v-model="form.terms_and_conditions"
                    type="checkbox"
                    id="register-terms"
                  />
                  <label for="register-terms"
                    >I agree to the
                    <a @click="show.agreementModal = true" href="#"
                      >User Agreement</a
                    ><span></span
                  ></label>
                </div>
                <ErrorPop
                  v-show="formErrorTerms"
                  :message="formErrorTerms"
                  @clearError="formErrors.terms_and_conditions = null"
                ></ErrorPop>
              </div>
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button
                  @click="setPassword"
                  class="btn btn-medium btn-arrow btn--primary waves-effect waves-dark mb60"
                >
                  <span>Set</span>
                  <i class="fa fa-chevron-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <UserAgreementModal
      v-show="show.agreementModal"
      @close="decline"
      @accept="accept"
    />
  </div>
</template>

<script>
import ErrorPop from '../../../common/ui/ErrorPop'
import UserAgreementModal from './UserAgreementModal'
import { http } from '@/services'

export default {
  name: 'LoginRegister',
  components: {
    ErrorPop,
    UserAgreementModal
  },
  created() {
    this.form.user_code = this.$route.params.code
    this.fetchUserDetails()
  },
  watch: {
    loginError: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value && value.detail) this.$notification.error(value.detail)
      }
    },
    form: {
      deep: true,
      immediate: true,
      handler(value) {
        this.formErrors = null
      }
    }
  },
  data() {
    return {
      form: {
        email: null,
        email_repeat: null,
        user_code: null,
        password1: null,
        password2: null,
        terms_and_conditions: false,
        passwordType: 'password'
      },
      formErrors: null,
      user: null,
      show: {
        agreementModal: false
      }
    }
  },
  computed: {
    formErrorEmail() {
      if (this.formErrors && this.formErrors.email)
        return this.formErrors.email[0]
      return null
    },
    formErrorPassword1() {
      if (this.formErrors && this.formErrors.password1)
        return this.formErrors.password1[0]
      return null
    },
    formErrorPassword2() {
      if (this.formErrors && this.formErrors.password2)
        return this.formErrors.password2[0]
      return null
    },
    formErrorTerms() {
      if (this.formErrors && this.formErrors.terms_and_conditions)
        return this.formErrors.terms_and_conditions[0]
      return null
    }
  },
  methods: {
    signIn() {
      this.$store.dispatch('obtainToken', {
        email: this.login.email,
        password: this.login.password
      })
    },
    setPassword() {
      http
        .post('/broker/api/v2/broker_handover_set_password', this.form)
        .then((response) => {
          this.$store.dispatch('_setTokens', response.data)
          this.$notification.success('New Password Set')
        })
        .catch((error) => {
          console.log(error)
          this.formErrors = error.response.data
        })
    },
    changeFormPasswordType() {
      if (this.form.passwordType === 'text') {
        this.form.passwordType = 'password'
      } else {
        this.form.passwordType = 'text'
      }
    },
    fetchUserDetails() {
      http
        .get('/broker/api/v2/broker_handover_account_detail_basic', {
          params: { user_code: this.form.user_code }
        })
        .then((response) => {
          this.user = response.data
          this.form.email = response.data.email
        })
        .catch((error) => {
          console.log(error)
          this.$notification.error('Invalid link')
          this.$router.push('/login')
        })
    },
    accept() {
      this.form.terms_and_conditions = true
      this.show.agreementModal = false
    },
    decline() {
      this.show.agreementModal = false
      this.form.terms_and_conditions = false
    }
  }
}
</script>

<style scoped>
.link {
  cursor: pointer;
}
.mb60 {
  margin-bottom: 60px;
}
.title {
  margin-bottom: 20px;
}
.bold {
  font-weight: bolder;
}
</style>
